<template>
  <div class="request-statuses">
    <div class="page_header">
      <h1>Статусы обращений</h1>
      <el-button type="primary" size="mini" @click="create" icon="el-icon-plus">Добавить</el-button>
    </div>
    <div class="page_content" v-loading="loading">
      <div class="list" v-if="statuses">
        <table class="table">
          <thead>
          <tr>
            <th></th>
            <th>Статус</th>
            <th></th>
            <th></th>
          </tr>
          </thead>
          <draggable tag="tbody" v-model="statuses">
            <tr v-for="(status, idx) in statuses" :key="status.id" class="drag_row">
              <td>
                <v-icon name="bars"/>
              </td>
              <td>
                <el-tag
                    class="statue_badge"
                    size="mini"
                    type="info"
                    :style="{
                      backgroundColor: status.bg,
                      borderColor: status.bg,
                      color: status.color,
                    }">
                  {{ status.title }}
                </el-tag>
              </td>
              <td>
                <el-tag size="mini" v-if="status.start == 1">Начальный</el-tag>
                <el-tag size="mini" v-if="status.finish == 1">Финальный</el-tag>
              </td>
              <td>
                <el-button
                    type="primary"
                    size="mini"
                    @click="openDrawer(status)"
                    icon="el-icon-edit">Изменить
                </el-button>
                <el-popconfirm
                    @confirm="remove(status.id)"
                    confirm-button-text='Да'
                    cancel-button-text='Нет'
                    title="Вы действительно хотите удалить?">
                  <el-button
                      slot="reference"
                      icon="el-icon-delete"
                      size="mini" type="danger"
                      style="margin-left: 5px;"></el-button>
                </el-popconfirm>
              </td>
            </tr>
          </draggable>
        </table>
      </div>
    </div>
    <el-drawer
        :visible.sync="drawerActive"
        direction="rtl"
        size="30%"
        custom-class="drawer_box"
        :before-close="closeDrawer"
        :destroy-on-close="true"
        :show-close="false"
        :wrapperClosable="false"
        :withHeader="false"
        :append-to-body="true"
        class="settings_drawer"
    >
      <div class="drawer_header">
        <h3 class="title">Статус</h3>
        <button class="close_btn" @click="closeDrawer">
          <i class="el-icon-close"></i>
        </button>
      </div>

      <div class="drawer_content" v-if="statusForm" v-loading="submitting">
        <el-form :model="statusForm" :rules="statusFormRules" ref="statusForm" class="form"
                 @submit.native.prevent="statusFormAction('statusForm')">
          <el-form-item label="Статус" prop="title">
            <el-input v-model="statusForm.title" size="mini"></el-input>
          </el-form-item>

          <el-row :gutter="10">
            <el-col :span="6" :offset="0">
              <el-form-item label="Цвет текста" prop="color">
                <el-color-picker v-model="statusForm.color"></el-color-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6" :offset="0">
              <el-form-item label="Цвет фона" prop="bg">
                <el-color-picker v-model="statusForm.bg"></el-color-picker>
              </el-form-item>
            </el-col>
          </el-row>


          <el-form-item label="Для какого этапа" prop="ыеуз">
            <el-radio-group v-model="statusForm.step" size="mini">
              <el-radio-button value="start" label="start">Начальный</el-radio-button>
              <el-radio-button value="free" label="free">Промежуточный</el-radio-button>
              <el-radio-button value="finish" label="finish">Финальный</el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item>
            <el-button
                type="primary"
                size="mini"
                @click="statusFormAction('statusForm')">Сохранить
            </el-button>
            <el-button size="mini" @click="closeDrawer">Отменить</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {actionTypes as statusAction} from "@/store/modules/statuses/request";
import draggable from 'vuedraggable'

export default {
  name: "RequestStatuses",
  data() {
    return {
      drawerActive: false,
      statusForm: null,
      statusFormRules: {
        title: [
          {required: true, message: 'Введите название статуса', trigger: 'blur'}
        ]
      }
    }
  },
  computed: {
    ...mapState({
      loading: state => state.statuses_for_requests.loading,
      submitting: state => state.statuses_for_requests.submitting,
      error: state => state.statuses_for_requests.error,
    }),
    statuses: {
      get() {
        return _.toArray(this.$store.state.statuses_for_requests.list)
      },
      set(list) {
        this.$store.dispatch(statusAction.reorder, list)
      }
    },
  },
  methods: {
    loadStatuses() {
      this.$store.dispatch(statusAction.get)
    },
    remove(id) {
      this.$store.dispatch(statusAction.remove, id).then(() => {
        this.loadStatuses()
      })
    },
    openDrawer(status) {
      this.statusForm = _.cloneDeep(status);
      this.drawerActive = true;
    },
    closeDrawer() {
      this.drawerActive = false;
      this.statusForm = null;
    },
    create() {
      this.statusForm = {
        title: "",
        color: "#333",
        bg: "#f7f7f7",
        step: "free",
        type: "request"
      };
      this.drawerActive = true;
    },
    statusFormAction(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store.dispatch(statusAction.save, this.statusForm).then(() => {
            this.loadStatuses()
            this.drawerActive = false
            this.statusForm = null
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
  watch: {},
  components: {
    draggable
  },
  created() {
    document.title = this.$route.meta.title ? this.$route.meta.title : 'GlassERP'
  }
}
</script>
